export const Toolbar_language = {
    TH: {

        // ในส่วนของแท็บเมนู ด้านซ้าย
        home_page:"หน้าแรก",
        stock:'สต๊อก',
        warehouse:"คลังสินค้า",
        my_product:"สินค้าของฉัน",
        product_stock:"สต๊อกสินค้า",
        warehouse_transfer:"นำเข้า/นำออก/โอนย้ายสินค้า ",
        warehouse_transfer_mobie1:"นำเข้า/นำออก ",
        warehouse_transfer_mobie2:"โอนย้ายสินค้า ",
        order:"ออเดอร์",
        create_order:"สร้างออเดอร์ใหม่",
        all_order:"ออร์เดอร์ทั้งหมด",
        stored_documents:"เอกสารที่จัดเก็บ",
        user:"ผู้ใช้",
        report:"รายงาน",
        report_cod:"รายงาน COD",
        report_sales:"รายงานยอดขาย",
        report_best_seller:"รายงานสินค้าขายดี",
        report_area_utilization:"รายงานการใช้พื้นที่",
        finance:"การเงิน",
        agent_finance:"การเงิน ตัวแทน",
        payment_notification:"แจ้งชำระเงิน ",
        balance_report:"รายงาน balance",
        invoice_group:"รายการใบแจ้งหนี้ที่จัดกลุ่ม",
        cod_wallet:"COD Wallet",
        agent_payment_notification:"แจ้งชำระเงิน (ตัวแทน)",
        agent_balance_report:"รายงาน balance (ตัวแทน)",
        consider_transfer_master:"ตัวแทนแจ้งโอนเงิน",
        shop_setting:"ข้อมูลทั่วไป",
        general_settings:"ตั้งค่าทั้วไป",
        connect:"เชื่อมต่อ",
        customer:"ลูกค้า",
        freight:"ค่าขนส่ง",
        feedback:"ข้อเสนอแนะ",
        change_language:"เปลี่ยนภาษา",
        thai_language:"ภาษาไทย",
        englist_language:"ภาษาอังกฤษ",
        china_language:"ภาษาจีน",


        //ในส่วนของ แท็บบาร์ด้านบน
        credit_balance:"เครดิตคงเหลือ",
        cod_balance:"COD คงเหลือ",
        bath:'บาท'
  
        
  },
    EN: {
       // ในส่วนของแท็บเมนู ด้านซ้าย
       home_page:"home page",
       stock:'stock',
       warehouse:"warehouse",
       my_product:"my product",
       product_stock:"Product in Stock",
       warehouse_transfer:"import/export/transfer ",
       order:"order",
       create_order:"Create new order",
       all_order:"all order",
       stored_documents:"Stored Documents",
       user:"user",
       report:" report",
       report_cod:" report COD",
       report_sales:" report Sales",
       report_best_seller:"Resport Bestseller",
       report_area_utilization:"Area utilization report",
       finance:"Finance",
       agent_finance:"การเงิน ตัวแทน", 
       payment_notification:"แจ้งชำระเงิน ", 
       balance_report:"รายงาน balance", 
       invoice_group:"รายการใบแจ้งหนี้ที่จัดกลุ่ม", 
       cod_wallet:"COD Wallet", 
       agent_payment_notification:"notice of payment (agent)",
       agent_balance_report:"report balance (agent)",
       consider_transfer_master:"ตัวแทนแจ้งโอนเงิน",
       shop_setting:"ข้อมูลทั่วไป",
       general_settings:"general settings",
       connect:"connect",
       customer:"customer",
       freight:"freight",
       feedback:"feedback",
       change_language:"change language",
       thai_language:"thai language",
       englist_language:"englist language",
       china_language:"china language",


       //ในส่วนของ แท็บบาร์ด้านบน
       credit_balance:"เครดิตคงเหลือ",
       cod_balance:"COD คงเหลือ",
       bath:'บาท' 
     },
    CH: {
        // ในส่วนของแท็บเมนู ด้านซ้าย
        home_page:"หน้าแรก",
        stock:'สต๊อก',
        warehouse:"คลังสินค้า",
        my_product:"สินค้าของฉัน",
        product_stock:"สต๊อกสินค้า",
        warehouse_transfer:"นำเข้า/นำออก/โอนย้ายสินค้า ",
        order:"ออเดอร์",
        create_order:"สร้างออเดอร์ใหม่",
        all_order:"ออร์เดอร์ทั้งหมด",
        stored_documents:"เอกสารที่จัดเก็บ",
        user:"ผู้ใช้",
        report:"รายงาน",
        report_cod:"รายงาน COD",
        report_sales:"รายงานยอดขาย",
        report_best_seller:"รายงานสินค้าขายดี",
        report_area_utilization:"รายงานการใช้พื้นที่",
        finance:"การเงิน",
        agent_finance:"การเงิน ตัวแทน", 
        payment_notification:"แจ้งชำระเงิน ", 
        balance_report:"รายงาน balance", 
        invoice_group:"รายการใบแจ้งหนี้ที่จัดกลุ่ม", 
        cod_wallet:"COD Wallet", 
        agent_payment_notification:"แจ้งชำระเงิน (ตัวแทน)",
        agent_balance_report:"รายงาน balance (ตัวแทน)",
        consider_transfer_master:"ตัวแทนแจ้งโอนเงิน",
        shop_setting:"ข้อมูลทั่วไป",
        general_settings:"ตั้งค่าทั้วไป",
        connect:"เชื่อมต่อ",
        customer:"ลูกค้า",
        freight:"ค่าขนส่ง",
        feedback:"ข้อเสนอแนะ",
        change_language:"เปลี่ยนภาษา",
        thai_language:"ภาษาไทย",
        englist_language:"ภาษาอังกฤษ",
        china_language:"ภาษาจีน",


        //ในส่วนของ แท็บบาร์ด้านบน
        credit_balance:"เครดิตคงเหลือ",
        cod_balance:"COD คงเหลือ",
        bath:'บาท'
    }
}
