<template>
  <div>
    <toolbar/>
    <div :height="$vuetify.breakpoint.xs ? '200px'
          : ($vuetify.breakpoint.sm ? '400px'
          : ($vuetify.breakpoint.md ? '600px'
          : ($vuetify.breakpoint.lg ? '800px' : '1000px'
          )))"
    >
      <div v-if="$vuetify.breakpoint.xs">
        <div class="hidden-sm-and-up packhai-container-mobile">
          <router-view/>
        </div>
      </div>
      <v-main v-else>
        <v-container class="hidden-xs-only packhai-container">
          <router-view/>
        </v-container>
      </v-main>
    </div>
  </div>
</template>
<script>
import Toolbar from '@/website/components/Toolbar'

export default {
  data () {
    return { show: false }
  },
  components: {
    Toolbar
  },
}
</script>
