import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('toolbar'),_c('div',{attrs:{"height":_vm.$vuetify.breakpoint.xs ? '200px'
        : (_vm.$vuetify.breakpoint.sm ? '400px'
        : (_vm.$vuetify.breakpoint.md ? '600px'
        : (_vm.$vuetify.breakpoint.lg ? '800px' : '1000px'
        )))}},[(_vm.$vuetify.breakpoint.xs)?_c('div',[_c('div',{staticClass:"hidden-sm-and-up packhai-container-mobile"},[_c('router-view')],1)]):_c(VMain,[_c(VContainer,{staticClass:"hidden-xs-only packhai-container"},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }